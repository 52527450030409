@import '../../index.scss';


.contact{
    min-height: 100vh;
    .contactTitle{
        font-size: 50px;  
    }
    .contactlinks{
        list-style: none;
        li{
            i{

                margin: 10px 15px 10px 0px;
                color: $redColor;
            }
        }
    }
    .social{
        list-style: none;
        display: flex; 
        li{
            margin-right: 20px;
            i{
                &:hover{
                    background-color: #eee;
                  color:  $redColor;
                }
                height: 40px;
                width: 40px;
                border: 1px solid #eee;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                font-size: 20px;
                transition:0.5s all ;
                -webkit-transition:0.5s all ;
                -moz-transition:0.5s all ;
                -ms-transition:0.5s all ;
                -o-transition:0.5s all ;
                padding: 10px;
            }
        }
    }
}