@import '../../index.scss';

// .about {
//     /* height: 100vh; */
//     padding: 50px;
   
// }
// .about .container-about {
//     margin-left: auto;
//     width:90%;
// }
// .about .container-about .item {
//     width: 40%;
//     float: left;
// }
// .about .item-border {
//     border:8px solid grey;
//     height: 450px;
//      position: relative;
//     width:100%
// }
// .about .item-border .img {
//     background-image: url('./images/profile.jpg');
//     background-size: cover;
//     height: 100%;
//     position: absolute;
//     top:15px;
//     left:15px;
//     width:100%;
//     overflow: hidden;
// } 
// .item-overlay {
//     position: absolute;
//     width:100%;
//     height: 100%;
//     background-color: rgb(0,0,0,0.5);
//     opacity: 0;
//     transition: all 1s ease-in-out;
//     -webkit-transition: all 1s ease-in-out;
//     -moz-transition: all 1s ease-in-out;
//     -ms-transition: all 1s ease-in-out;
//     -o-transition: all 1s ease-in-out;
//     top:100%;
//     left:0   
// }
// .img:hover .item-overlay{
//     opacity: 1;
//     top:0   
// } 

// .item-overlay ul {
//     list-style: none;
//     position: absolute;
//     bottom: 50%;
//     right:35%; 
// }
// .item-overlay ul li {
//     display: inline-block;
//     margin-left:15px 
// }
// .item-overlay ul li i {
//     color:#fff;
//     font-size: 20px;
// }
// .about-title {
//     width: 50%;
//     float: right;
// }

// .about-title h2 {
//     letter-spacing: 2px;
//     font-size: 34px;
//     font-weight: 600;  
// }
// .about-title h2::before{
//     background-color: #ff305b;
//     content: " ";
//     height: 25px;
//     display: inline-block;
//     width: 5px;
//     margin-right: 10px;  
// }
// .about-title p {
   
//     font-family: 'Roboto', sans-serif;
//     line-height: 23px;
//     color:#555;
//     font-weight: 500;
//     padding: 20px 0;
//     width: 100%;
// }
// .about-border {
//     border:1px dashed #999999;
    
// }
// .list {
//     width: 80%;
//     margin: 20px 0;
   
// }
// .list  ul  {
//     list-style: none;  
    
// }
// .list  ul li  {
//     margin-bottom: 15px;
//     font-family: 'Roboto', sans-serif;
   
   
// }
// .list .one {
//     float: left; 
// }
// .list .two {
//     float: right;
// }
// .list span {
//     color: #333333;
//     font-weight: bold
// }
// .btn {
//     background: #ff305b;
//     color: #fff;
//     border: 1px solid transparent;
//     padding: 10px;
//     width:150px;
//     font-size: 17px;
//     border-radius: 5px;
//     -webkit-border-radius: 5px;
//     -moz-border-radius: 5px;
//     -ms-border-radius: 5px;
//     -o-border-radius: 5px;
//     margin-left:10px;
//     transition: 1s all;
//     -webkit-transition: 1s all;
//     -moz-transition: 1s all;
//     -ms-transition: 1s all;
//     -o-transition: 1s all;
// }

// .btn i {
//     margin-left:5px
// }

// .btn:hover {
//     background-color: orangered;
// }

.about {
    padding: 60px 0;
    .itemBorder{
        border:8px solid $redColor;
            height: 450px;
             position: relative;
            width:100%;
    }
    .overlay{
        position: absolute;
    width:100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);
    opacity: 0;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    top:100%;
    left:0; 
    ul{
        list-style: none;
            position: absolute;
            bottom: 50%;
            right:35%; 
            display: flex;
            justify-content: space-between;
            align-items: center;
            li{
              margin-left: 10px;
               i{
                color:#fff;
                 font-size: 20px;
               }
            }
    }
    }
    .img{ 
        &:hover .overlay{
                opacity: 1;
                top:0;
        }
        background: {
            image:url('../../../public/images/profile.jpg'); 
            size: cover;
        };
        height: 100%;
            position: absolute;
            top:15px;
            left:15px;
            width:100%;
            overflow: hidden;
    }
   .aboutTitle{
    h2{
        color: $redColor;
        font-size: 30px;
        font-weight: bold;
       
    }
    p{
        text-align: justify;
    }
    .one{
        list-style: none;
        li{
            margin-bottom: 10px;
        }
    }
    .two{
        list-style: none;
        li{
            margin-bottom: 10px;
        }
    }
   }
}


