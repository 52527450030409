@import '../../index.scss';

.reg{
    min-height: 80vh;
    width: 50%;
    margin: auto;
    padding-top: 40px;
}



@media screen and (max-width:767px){
    .reg{
        width: 100% !important;
    }
    .info h4{
        padding: 20px 0;
    }
}

// @media screen and (min-width:768px) and (max-width:991px){
//     .item{
//         display: none;
//     }
// }