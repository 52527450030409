
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Roboto:wght@300&family=Space+Mono:ital@1&family=Urbanist:wght@100&family=Xanh+Mono:ital@1&display=swap');


$bgColor:#1c2331;
$BgColor:rgb(0, 0, 0,0.2);
$mainbgClr:#1abc9c;
$textClr:#fff;
$borderColor:#a8a8a83d;
$borderHeight:1px;
$secondFontColor:#949cb0;
$redColor:#6351ce;

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    
    
  }
  body{
    background-color:$bgColor ;
    color: $textClr;
    font-family: 'Roboto', sans-serif;
  }
  .yellowbg {
    background-color:$redColor ;
}
a,a:hover{
    color: $textClr !important;
}
.myBtn{
    color:$textClr !important;
    background-color:$redColor!important;
    font-weight: 700 !important;
    border: 1px solid #6351ce !important;
}
@mixin borderRadius($x){
    border-radius: $x;
    -webkit-border-radius: $x;
    -moz-border-radius: $x;
    -ms-border-radius: $x;
    -o-border-radius: $x;
  }