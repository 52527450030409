@import '../../index.scss';


.mainNav{
    // background-color: $BgColor !important;
    .brand{
       
        font-family: 'Xanh Mono', monospace;
        font-size: 26px;
    }
}