@import '../../index.scss';


.footer{
    background-color: $bgColor;
    .headfooter{
        background-color: $redColor;
    }
    hr{
        width: 60px;
         background: '#7c4dff';
         height: 2
    }
    .copy{
        background-color: rgba(0, 0, 0, 0.2);
        letter-spacing: 0.5px;
        span{

            font-family: 'Xanh Mono', monospace;
        }
        
    }
}