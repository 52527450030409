@import '../../index.scss';


.home{
    background: {
        image:url('../../../public/images/4.jpg');
        position: center center;
        size:cover;
        repeat:no-repeat;
        attachment:fixed;
    }
   min-height: 90vh;
   position: relative;
   display: flex;
   align-items: center;
  
   .overlay{
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    position: absolute;
   }
   .cont{
    display: flex;
    justify-content: center;
   }
   .info{
    text-align: center;
    color: #eee;
    .title{
        font-size: 60px;
    }
   }
   
}

.about{
    .aboutTitle{
        color: $redColor;
        font-size: 60px;
        font-family: 'Xanh Mono', monospace;
        text-align: center;
    }

     img{
     @include borderRadius(10px)
}
   
}